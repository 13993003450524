import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import useStyle from '../styles';

const { Text } = Typography;

const Input = ({ name, label, ...props }) => {
  const classes = useStyle();
  return (
    <div className={classes.inputGroup}>
      <Text className={`${classes.label} ${classes.inputLabels}`}>{label}</Text>
      <Field className={classes.input} name={name} {...props} />
      <ErrorMessage name={name}>
        {(msg) => <span className={classes.label}>{msg}</span>}
      </ErrorMessage>
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Input;
