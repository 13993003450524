import api from 'util/Api';
import axios from 'axios';
import { API, ALLOWEDROLES, GeoCodeParameters } from 'default';
import { getCurrentPosition } from 'default/operations';
import { saveKey } from 'util/localstorage';
import {
  put,
  call,
  all,
  takeLatest,
  select,
  take,
  fork,
  cancel,
  race,
} from 'redux-saga/effects';
import { logout, clearReducers } from 'redux/logout';
import { message } from 'antd';
import { authTokenSelector } from './selectors';
import { actions } from './slice';

function* getGeocodeParameters() {
  const parameters = { ...GeoCodeParameters };
  try {
    if (navigator.geolocation) {
      const { coords } = yield call(getCurrentPosition);
      const latitude = parseFloat(coords.latitude).toFixed(6);
      const longitude = parseFloat(coords.longitude).toFixed(6);
      parameters.prox = `${latitude},${longitude}`;
    }
  } catch (error) {
    // do nothing
  }
  yield put({ type: actions.setGeocode.type, payload: parameters });
}

function* fetchProfileSaga() {
  const profileResponse = yield call(api.apiAxios.get, API.profile.load);
  if (!api.isSuccessResponse(profileResponse)) {
    throw new Error(
      `Error al obtener el perfil del usuario: ${profileResponse.data.message}`,
    );
  }
  const profile = profileResponse.data.data;
  if (!ALLOWEDROLES.some((allowedRole) => allowedRole === profile.userRoleID)) {
    throw new Error('El usuario no es administrador');
  }
  return profile;
}

function* fetchLoggedUserSaga() {
  const sessionToken = yield select(authTokenSelector);
  if (sessionToken) {
    api.apiAxios.defaults.headers.common.authorization = `bearer ${sessionToken}`;
    try {
      const profile = yield call(fetchProfileSaga);
      yield put({ type: actions.fetchProfileSuccess.type, payload: profile });
      yield fork(getGeocodeParameters);
    } catch (error) {
      yield put({ type: actions.fetchProfileFailure.type });
      message.error(error.message);
    }
  }
}

function* fetchLoginSaga(email, password) {
  const body = new URLSearchParams({
    username: email,
    password,
    grant_type: 'password',
  });
  const request = {
    url: API.auth.login,
    method: 'POST',
    data: body.toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  try {
    const response = yield call(axios, request);
    const token = response.data.access_token;
    api.apiAxios.defaults.headers.common.authorization = `bearer ${token}`;
    const profile = yield call(fetchProfileSaga);
    const payload = {
      profile,
      sessionToken: token,
    };
    saveKey('sessionToken', token);
    yield put({ type: actions.success.type, payload });
    yield fork(getGeocodeParameters);
  } catch (error) {
    yield put({ type: actions.failure.type });
    if (error && error.message.includes(400)) {
      message.error('Usuario o contraseña incorrectos.');
    } else {
      message.error(error.message);
    }
  }
}

function* watchLogin() {
  while (true) {
    const { credentials } = yield race({
      credentials: take(actions.fetch.type),
      rehydrate: take(actions.fetchLoggedUser.type),
    });
    let task;
    if (credentials) {
      const {
        payload: { email, password },
      } = credentials;
      task = yield fork(fetchLoginSaga, email, password);
    }
    yield take([
      logout.type,
      actions.failure.type,
      actions.fetchProfileFailure.type,
    ]);
    localStorage.removeItem('sessionToken');
    yield put({ type: clearReducers.type });
    if (task) {
      yield cancel(task);
    }
  }
}

export default function* rootSaga() {
  yield all([
    yield fork(watchLogin),
    yield takeLatest(
      [actions.fetchLoggedUser.type, actions.reloadProfile.type],
      fetchLoggedUserSaga,
    ),
  ]);
}
