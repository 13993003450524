import { createSelector } from 'reselect';
import { name, initialState } from './slice';

export const marketplacesSelector = (state) => state[name] || initialState;

export const marketplacesObjectItemSelector = (state) => state[name].items;

export const marketplacesItemsSelector = createSelector(
  marketplacesObjectItemSelector,
  (items) => Object.values(items),
);

export const marketplacesSearchSelector = (state) => state[name].search;

export const marketplacesLoadedSelector = (state) => state[name].loaded;

export const marketplacesPageableSelector = (state) => state[name].pageable;

const makeMarketplaceSelector = () =>
  createSelector(marketplacesSelector, (substate) => substate);

export const marketplacesFilteredItemsSelector = createSelector(
  marketplacesItemsSelector,
  marketplacesPageableSelector,
  marketplacesSearchSelector,
  (items, { pageSize, pageNumber }, search) => {
    let resultItems = items;
    if (search) {
      resultItems = resultItems.filter((item) => {
        const searchIgnoreCase = search.toUpperCase();
        const storeNameIgnoreCase = item.name.toUpperCase();
        return storeNameIgnoreCase.includes(searchIgnoreCase);
      });
    }
    const start = pageNumber * pageSize;
    const end = pageNumber * pageSize + pageSize;
    const totalElements = resultItems.length;
    resultItems = resultItems.slice(start, end);
    return {
      pageable: {
        pageNumber,
        pageSize,
        totalElements,
      },
      items: resultItems,
    };
  },
);

export default makeMarketplaceSelector;
