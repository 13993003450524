import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';
import { Switch, Route } from 'react-router-dom';
import useStyles from './styles';
import Main from './components/Main';
import RecoverPassword from './components/RecoverPassword';
import ConfirmRecovery from './components/ConfirmRecovery';

const { Text } = Typography;

const Login = ({
  applicationName,
  submit,
  serviceLogo,
  isLogginIn,
  loginFailure,
  URL,
  paqueryLogo,
}) => {
  const classes = useStyles();
  return (
    <Row type="flex" justify="center" align="middle" className={classes.layout}>
      <Col className={classes.loginBox} xs={22} md={16} lg={12} xl={10} xxl={6}>
        <Row type="flex" justify="center" style={{ margin: '40px 0' }}>
          <Col>
            <img src={serviceLogo} alt="Logo" style={{ height: '45px' }} />
          </Col>
          {!paqueryLogo && (
            <Col>
              <p className={classes.loginHeaderText}>
                E-Commerce Delivery Partner
              </p>
            </Col>
          )}
        </Row>
        <Text className={classes.loginText}>{applicationName}</Text>
        <Row type="flex" justify="center">
          <Col className={classes.loginBox} lg={{ span: 14 }} xs={{ span: 18 }}>
            <Switch>
              <Route
                path="/login"
                render={(props) => (
                  <Main
                    onSubmit={submit.login}
                    isLogginIn={isLogginIn}
                    loginFailure={loginFailure}
                    classes={classes}
                    {...props}
                  />
                )}
              />
              <Route
                path="/recoverPassword"
                render={(props) => (
                  <RecoverPassword
                    onSubmit={submit.recover}
                    URL={URL.recover}
                    classes={classes}
                    {...props}
                  />
                )}
              />
              <Route
                path="/confirmRecovery"
                render={(props) => (
                  <ConfirmRecovery
                    onSubmit={submit.confirm}
                    URL={URL.confirm}
                    classes={classes}
                    {...props}
                  />
                )}
              />
            </Switch>
          </Col>
        </Row>
        {paqueryLogo && (
          <Row type="flex" justify="center" style={{ marginTop: '90px' }}>
            <Col>
              <p className={classes.label} style={{ fontSize: '10px' }}>
                powered by
              </p>
            </Col>
            <Col>
              <img
                src={paqueryLogo}
                alt="Paquery logo"
                style={{
                  marginLeft: '15px',
                  marginTop: '14px',
                  height: '15px',
                }}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

Login.defaultProps = {
  paqueryLogo: undefined,
};

Login.propTypes = {
  applicationName: PropTypes.string.isRequired,
  serviceLogo: PropTypes.string.isRequired,
  submit: PropTypes.shape({
    login: PropTypes.func.isRequired,
    recover: PropTypes.func,
    confirm: PropTypes.func,
  }).isRequired,
  isLogginIn: PropTypes.bool.isRequired,
  loginFailure: PropTypes.bool.isRequired,
  URL: {
    recover: PropTypes.string.isRequired,
    confirm: PropTypes.string.isRequired,
  }.isRequired,
  paqueryLogo: PropTypes.string,
};

export default Login;
