import { GLOBALS } from 'paquery-language/keys';
import useGetValueNameArray from './utils';

const keys = [
  {
    name: GLOBALS.PACKAGE.SIZE.SMALL,
    value: 1,
  },
  {
    name: GLOBALS.PACKAGE.SIZE.MEDIUM,
    value: 2,
  },
  {
    name: GLOBALS.PACKAGE.SIZE.BIG,
    value: 3,
  },
  {
    name: GLOBALS.PACKAGE.SIZE.CUSTOM,
    value: 4,
  },
];

export default () => useGetValueNameArray(keys);
