import { getData } from 'util/Api';
import { API, haveDateLoaded } from 'default';
import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import { actions } from './slice';
import { selectPackagePaquersHistory } from './selectors';

const URL = API.packages.history;

function* fetchSaga() {
  try {
    const {
      pageable: { pageNumber, pageSize, totalElements },
      search,
      status,
      type,
      date,
      paquerId,
      marketplaceId,
    } = yield select(selectPackagePaquersHistory);
    const searchParams = new URLSearchParams({
      page: pageNumber,
      size: pageSize,
      total: totalElements,
      desc: true,
      paquerId,
      packageType: type,
      isScheduled: false,
      marketplaceId: marketplaceId || '',
      status,
      search,
      type,
      from: date.from,
      to: date.to,
      sortColumn: '',
      city: '',
    });
    const packages = yield call(getData, `${URL}?${searchParams.toString()}`);
    const payload = {
      items: packages.content,
      total: packages.totalElements,
    };
    yield put({ type: actions.success.type, payload });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* updateBasefilters() {
  const { status, date, marketplaceId } = yield select(
    selectPackagePaquersHistory,
  );
  if (status === null || !haveDateLoaded(date) || marketplaceId == null) {
    return;
  }
  yield put({
    type: actions.fetch.type,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      [
        actions.updateDate,
        actions.updatePagination,
        actions.updateSearch,
        actions.updatePaquer,
        actions.updateStatus,
        actions.updatePackageType,
        actions.updatePacket,
        actions.updateMarket,
        actions.refreshPage,
      ],
      updateBasefilters,
    ),
    takeLatest([actions.fetch], fetchSaga),
  ]);
}
