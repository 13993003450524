import React, { useEffect, useCallback, useState } from 'react';
import rest from 'util/Api';
import JsDownload from 'js-file-download';
import moment from 'moment';
import { notification } from 'antd';
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import PaqueryTable from '@paquery-team/lib-table';
import ViewPackageButton from 'components/viewPackageButton';
import {
  TableDateTimeFormat,
  defaultPaginate,
  defaultDateFilterPackageHistory,
  API,
  haveDateLoaded,
  FILTER_FOR_PACKAGE_TYPES,
  SITELINKS,
} from 'default';
import usePackagesWithPaquersHistorical from 'redux/packagesWithPaquersHistorical';
import ModalButton from 'components/modalButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobals } from 'redux/globals/selectors';
import {
  packagePaquersHistorySearchSelector,
  packagePaquersHistoryDateSelector,
  packagePaquersHistoryLoadedSelector,
  packagePaquersHistoryPaquerSelector,
  packagePaquersHistoryTypeSelector,
  packagePaquersHistoryMarketSelector,
  packagePaquersHistoryStatusSelector,
  packagePaquersHistoryItemsSelector,
  packagePaquersHistoryPaginationSelector,
} from 'redux/packagesWithPaquersHistorical/selectors';
import { paquersItemsSelector } from 'redux/paquers/selectors';
import { actions } from 'redux/packagesWithPaquersHistorical/slice';
import { actions as paquerActions } from 'redux/paquers/slice';
import usePaquers from 'redux/paquers';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import useMarketplaceOptions from 'app/hooks/useMarketplaceOptions';
import { Link } from 'react-router-dom';
import useMarketplaces from 'redux/marketplaces';

const statusStyle = (statusText) => {
  let style = {
    background: 'gray',
    padding: 4,
    borderRadius: '5px',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: 'white',
  };
  switch (statusText) {
    case 'Entregado':
      style.background = 'green';
      break;
    case 'Cancelado':
      style.background = 'red';
      break;
    case 'Devuelto':
      style.background = 'gold';
      break;
    case 'Siniestrado':
      style.background = 'orange';
      break;
    default:
      style = {};
      break;
  }
  return { style };
};

const driverAvatar = (record) => (
  <ModalButton
    name={record.driverName}
    image={record.driverImage}
    icon={<UserOutlined />}
  />
);

const signatureAvatar = (record) => (
  <ModalButton
    name={record.signatureName}
    shape="square"
    image={record.signatureImage}
  />
);

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text) => <span style={{ wordBreak: 'break-word' }}>{text}</span>,
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const contentColumn = {
  title: 'Contenido',
  dataIndex: 'content',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const deliveryDateColumn = {
  title: 'Fecha de entrega',
  dataIndex: 'deliveryDate',
};

const driverColumn = {
  title: 'PaQuer',
  align: 'center',
  render: (_, record) => driverAvatar(record),
};

const driverNameColumn = {
  title: 'Conductor',
  dataIndex: 'driverName',
};

const signatureColumn = {
  title: 'Firma',
  render: (_, record) => signatureAvatar(record),
};

const signatureNameColumn = {
  title: 'Firma',
  dataIndex: 'signatureName',
};

const signatureTypeColumn = {
  title: 'Tipo de Firma',
  dataIndex: 'signatureType',
  width: 140,
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
  render: (text) => <span style={statusStyle(text)}>{text}</span>,
};

const editColumn = {
  align: 'center',
  render: (_, record) => (
    <Link
      to={{
        pathname: `${SITELINKS.packages.list}/id/${record.id}`,
        state: { packet: record },
      }}
    >
      <EditOutlined />
    </Link>
  ),
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packet={record} />,
};

const dataColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureNameColumn,
  signatureTypeColumn,
  driverNameColumn,
  statusColumn,
];

const fullSizeColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  driverColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureColumn,
  signatureTypeColumn,
  statusColumn,
  editColumn,
  viewPackageColumn,
];

const columnsSmallDevice = [externalCodeColumn, editColumn, viewPackageColumn];

const columnsMediumDevice = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  editColumn,
  viewPackageColumn,
];

const getAllPaquersForCsvUrl = (
  date,
  search,
  packageType,
  status,
  marketplaceId,
) => {
  const searchParams = new URLSearchParams({
    search,
    from: date.from,
    to: date.to,
    packageType: packageType || '',
    status: status || '',
    marketplaceId: marketplaceId || '',
    paquerView: true,
  });
  return `${API.reports.history}?${searchParams.toString()}`;
};

const getPaquerByIdCsvUrl = (
  search,
  date,
  packageType,
  status,
  marketplaceId,
  paquerId,
) => {
  const searchParams = new URLSearchParams({
    search,
    paquerId,
    to: date.to,
    from: date.from,
    packageType: packageType || '',
    status: status || '',
    marketplaceId: marketplaceId || '',
  });
  return `${API.reports.historyByPaquer}?${searchParams.toString()}`;
};

const getCsvFilename = (paquerFullName) =>
  `Paquetes-Historial-${paquerFullName ? `${paquerFullName}-` : ''}${moment()
    .format('DD-MM-YY HH_mm_ss')
    .toString()}.xls`;

const PackageHistory = () => {
  usePackagesWithPaquersHistorical();
  useMarketplaces();
  usePaquers();
  const dispatch = useDispatch();
  const globals = useSelector(selectGlobals);
  const paquers = useSelector(paquersItemsSelector);
  const [paquerList, setPaquerList] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplacesOptions = useMarketplaceOptions(marketplaces);
  const marketFilter = useSelector(packagePaquersHistoryMarketSelector);
  const packageType = useSelector(packagePaquersHistoryTypeSelector);
  const currentPaquer = useSelector(packagePaquersHistoryPaquerSelector);
  const search = useSelector(packagePaquersHistorySearchSelector);
  const date = useSelector(packagePaquersHistoryDateSelector);
  const status = useSelector(packagePaquersHistoryStatusSelector);
  const loaded = useSelector(packagePaquersHistoryLoadedSelector);
  const items = useSelector(packagePaquersHistoryItemsSelector);
  const pageable = useSelector(packagePaquersHistoryPaginationSelector);

  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      if (!haveDateLoaded(date) || currentPaquer === null) {
        notification.warning({
          message: 'Es necesario indicar una fecha y algun paquer',
          description:
            'Para poder descargar el Reporte es necesario cargar estos datos',
        });
        return;
      }
      let URL;
      let filename;
      if (currentPaquer === '') {
        URL = getAllPaquersForCsvUrl(
          date,
          search,
          packageType,
          status,
          marketFilter,
        );
        filename = getCsvFilename();
      } else {
        const paquerFullName =
          currentPaquer !== '' &&
          paquerList.find((paquer) => paquer.value === currentPaquer).name;
        filename = getCsvFilename(paquerFullName);
        URL = getPaquerByIdCsvUrl(
          search,
          date,
          packageType,
          status,
          marketFilter,
          currentPaquer,
        );
      }
      const response = await rest.get(URL, {
        responseType: 'blob',
      });
      JsDownload(response.data, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al exportar el archivo${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    dispatch(paquerActions.initial());
  }, [dispatch]);

  useEffect(() => {
    if (paquers.length > 0) {
      setPaquerList(
        paquers.map((paquer) => ({
          value: paquer.id,
          name: `${paquer.name} ${paquer.lastName}`,
        })),
      );
    }
  }, [paquers]);

  const searchCallback = useCallback(
    (searchText) => {
      dispatch(actions.updateSearch(searchText));
    },
    [dispatch],
  );
  const dateSelectionCallback = useCallback(
    (newDate) => {
      dispatch(actions.updateDate(newDate));
    },
    [dispatch],
  );
  const selectPaquerCallback = useCallback(
    (newPaquer) => {
      dispatch(actions.updatePaquer(newPaquer));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (marketSelected) => {
      dispatch(actions.updateMarket(marketSelected));
    },
    [dispatch],
  );
  const selectPackageTypeCallback = useCallback(
    (newPackageType) => {
      dispatch(actions.updatePackageType(newPackageType));
    },
    [dispatch],
  );
  const searchers = [
    {
      onSearching: searchCallback,
      placeholder: 'Buscar cod. o destinatario',
    },
  ];
  const selectors = [
    {
      onChange: selectMarketplaceCallback,
      placeholder: 'Seleccione Marketplace',
      defaultValue: marketFilter,
      xsSpan: 24,
      list: marketplacesOptions,
      required: {
        value: true,
        message: 'Se necesita indicar Marketplace',
      },
      showAll: true,
    },
    {
      onChange: selectPackageTypeCallback,
      placeholder: 'Seleccione tipo de envio',
      defaultValue: packageType,
      xsSpan: 24,
      list: FILTER_FOR_PACKAGE_TYPES,
      showAll: true,
    },
    {
      list: paquerList,
      defaultValue: currentPaquer,
      onChange: selectPaquerCallback,
      placeholder: 'Seleccione un paquer',
      required: {
        value: true,
        message: 'Se necesita indicar un paquer',
      },
      showAll: true,
    },
  ];
  let filteredPackages = [];
  if (loaded && items) {
    filteredPackages = items.map((packet) => {
      if (currentPaquer) {
        if (packet.shippingScheduleDestination.driver?.id !== currentPaquer) {
          // eslint-disable-next-line no-console
          console.log('PAQUETE DE OTRO DRIVER: ', packet.externalCode);
        }
      }
      if (packet.shippingScheduleDestination.driver === null) {
        // eslint-disable-next-line no-console
        console.log('PAQUETE SIN DRIVER: ', packet.externalCode);
      }
      return {
        ...packet,
        key: packet.id,
        externalCode: packet.externalCode,
        destinationAddress:
          packet.shippingScheduleDestination.shippingAddress.addressDetail,
        deliveryTerm: globals.deliveryTerm.find(
          (term) => term.value === packet.deliveryTerm,
        ).description,
        content: packet.caption,
        driverImage: packet.shippingScheduleDestination.driver
          ? packet.shippingScheduleDestination.driver.avatar
          : null,
        driverName: packet.shippingScheduleDestination.driver
          ? `${packet.shippingScheduleDestination.driver.name} ${packet.shippingScheduleDestination.driver.lastName}`
          : null,
        deliveryDate: packet.deliveryDate
          ? moment(packet.deliveryDate).format(TableDateTimeFormat)
          : null,
        arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
          ? moment(packet.arrivedAtPaqueryPointDate).format(TableDateTimeFormat)
          : null,
        signatureImage: packet.signatureImage,
        signatureName: packet.shippingScheduleDestination.name,
        signatureType: globals.packages.deliveryEndorsementType.find(
          (endorsement) =>
            endorsement.value ===
            packet.shippingScheduleDestination.deliveryPackageType,
        )?.name,
        statusDescription: globals.packages.status.find(
          (state) => state.value === packet.status,
        ).name,
      };
    });
  }
  const key = 'dateFilterNotification';
  if (date === defaultDateFilterPackageHistory && !loaded) {
    notification.info({
      message: 'Se están mostrando los paquetes del último mes:',
      description:
        'Para ajustar su búsqueda utilice los filtros en la parte superior de la tabla.',
      duration: 10,
      key,
      onClick: () => notification.close(key),
    });
  }

  const onChangePaginate = (newPagination) => {
    dispatch(actions.updatePagination(newPagination));
  };

  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        searchers,
        selectors,
        dateItems: {
          onDateSelection: dateSelectionCallback,
          required: {
            value: true,
            message: 'La fecha es requerida',
          },
        },
        refresh: () => dispatch(actions.refreshPage()),
        onExportCsv: { callback: handleExportCsv, disabled },
      }}
      usePackageRowColors
      onChangePaginate={onChangePaginate}
      dataSource={filteredPackages}
      paginate={pageable || defaultPaginate}
      dataColumns={dataColumns}
      colsForMediumDevice={columnsMediumDevice}
      colsForSmallDevice={columnsSmallDevice}
      colsForLargeDevice={fullSizeColumns}
    />
  );
};

export default PackageHistory;
