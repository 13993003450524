import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  layout: {
    minHeight: '100%',
    fontFamily: 'Open Sans,sans-serif',
    background: theme.background || '',
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  loginTitle: {
    fontSize: '2rem',
  },
  loginText: {
    fontWeight: '300',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.2',
    letterSpacing: 'normal',
    fontSize: '36px',
    padding: '40px 0',
    color: theme.primary || '',
  },
  loginError: {
    minHeight: '24px',
    marginBottom: '6px',
  },
  logo: {
    width: '200px',
    height: '32px',
    background: 'rgba(255,255,255, 0.2)',
    margin: '60px auto 0 auto',
  },
  rounded: {
    borderRadius: '16px',
  },
  input: {
    height: '34px',
    padding: '5px',
    borderRadius: '6px',
  },
  label: {
    margin: '13px 0',
    textAlign: 'center',
    color: theme.primary || '',
  },
  inputLabels: {
    textAlign: 'left',
    fontWeight: 'bold',
  },
  submitButton: {
    margin: '17px 0',
    fontWeight: 'bold',
    background: theme.secondary || '',
  },
  loginHeaderText: {
    width: '85px',
    fontSize: '9px',
    textAlign: 'left',
    marginTop: '4px',
    marginLeft: '10px',
    color: theme.secondary || '',
  },
  inputGroup: {
    height: '110px',
    display: 'flex',
    flexDirection: 'column',
  },
}));
