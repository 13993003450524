import React, { useCallback } from 'react';
import { ThemeProvider } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import Logo from 'default/paquery-logo.png';
import { authIsLoginSelector } from 'redux/auth/selectors';
import { actions } from 'redux/auth/slice';
import { API } from 'default';
import LoginComponent from './screen';

const URL = {
  recover: API.auth.recover,
  confirm: API.auth.confirm,
};

const Login = () => {
  const dispatch = useDispatch();
  const isLoginIn = useSelector(authIsLoginSelector);
  const submitLogin = useCallback(
    (values) => {
      dispatch(actions.fetch(values));
    },
    [dispatch],
  );
  const submit = {
    login: submitLogin,
  };

  const theme = {
    background: '#254370',
    primary: '#f6f6f6',
    secondary: '#0db8b2',
  };

  return (
    <ThemeProvider theme={theme}>
      <LoginComponent
        applicationName="Tenant"
        submit={submit}
        isLogginIn={isLoginIn}
        loginFailure={false}
        serviceLogo={Logo}
        URL={URL}
      />
    </ThemeProvider>
  );
};

export default Login;
