import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function useGetValueNameArray(keys) {
  const [translations, setTranslations] = useState([]);
  const [t] = useTranslation();
  useEffect(() => {
    setTranslations(
      keys.map((key) => ({
        value: key.value,
        name: t(key.name),
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);
  return translations;
}
