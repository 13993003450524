import React from 'react';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const ViewPackageButton = ({ packet }) => (
  <Link to={`/packages/view/${packet.id}`}>
    <EyeOutlined />
  </Link>
);

ViewPackageButton.propTypes = {
  packet: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ViewPackageButton;
