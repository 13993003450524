import { API, getCaronteBasicAuth } from 'default';
import rest, { getData } from 'util/Api';

export const fetchPackage = async (id) => {
  try {
    if (id === null || id === '') {
      const error = new Error('El id no puede ir vacío.');
      return error;
    }
    const packet = await getData(`${API.packages.getById}${id}`);
    return packet;
  } catch (error) {
    if (error.message.includes(404)) {
      return new Error('No se ha encontrado el paquete con el id solicitado');
    }
    return error;
  }
};

export const fetchPaquer = async (id) => {
  try {
    if (id === null || id === '') {
      const error = new Error('El id del paquer no puede ir vacío.');
      return error;
    }
    const paquer = await rest.get(`${API.paquer.get}${id}`);
    if (!paquer.data.data) {
      const error = new Error(
        'El paquete contiene un paquer cuyo id indicado no existe',
      );
      return error;
    }
    return paquer.data.data;
  } catch (error) {
    return error;
  }
};

const fetchResolveZone = async (address, oplId) => {
  const zone = { w3wCode: null, zoneId: null, zoneName: null };
  const options = {
    headers: {
      authorization: getCaronteBasicAuth(),
    },
  };
  const zoneRequest = await rest.post(API.zones.resolve, { address, oplId }, options);
  const data = zoneRequest.data?.data;
  if (data) {
    zone.w3wCode = data.w3w;
    zone.zoneId = data.zoneId;
    zone.zoneName = data.zoneName;
  }
  return zone;
};

export const getW3WCodeFromAddress = async (address) => {
  const zone = await fetchResolveZone(address);
  return zone.w3wCode;
};

export const getZoneAndW3WFromAddress = async (address, logisticOperatorID) => {
  const zone = await fetchResolveZone(address, logisticOperatorID);
  return {
    zone: {
      id: zone.zoneId,
      name: zone.zoneName,
    },
    w3wCode: zone.w3wCode,
  };
};
export const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

export const getCoordsFromPolygonGeoJson = (geoJson) => {
  if (!geoJson)
    throw new Error('La zona leida no tiene parametros de geojson requeridos.');
  if (!geoJson.geometry)
    throw new Error(
      'El geojson de la zona recibida le esta faltando la propiedad geometria',
    );
  if (geoJson.geometry.type !== 'Polygon')
    throw new Error(
      'La zona requerida no tiene el tipo de geojson requerido para poligono.',
    );
  if (!geoJson.geometry.coordinates)
    throw new Error('La zona recibida no tiene coordenadas cargadas');
  return geoJson.geometry.coordinates[0].map((coord) => ({
    lat: coord[0],
    lng: coord[1],
  }));
};

export default {};
