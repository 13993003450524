import { useState, useEffect } from 'react';

export default function useMarketplaceOptions(marketplaceList) {
  const [marketplaceOptions, setMarketplaceOptions] = useState([]);
  useEffect(() => {
    if (marketplaceList && marketplaceList.length > 0) {
      const options = marketplaceList.map((market) => ({
        value: market.id,
        name: market.name,
      }));
      setMarketplaceOptions(options);
    }
  }, [marketplaceList]);
  return marketplaceOptions;
}
