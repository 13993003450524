import React, { useCallback, useEffect, useState } from 'react';
import PackageViewComponent from '@paquery-team/lib-package-view';
import { fetchPackage, fetchPaquer } from 'default/operations';
import { useParams, useHistory } from 'react-router-dom';
import {
  usePackageStatus,
  useDeliveryTerms,
  usePackageSizes,
} from 'hooks/globals';
import { useSelector } from 'react-redux';
import { selectCountries } from 'redux/globals/selectors';
import JsDownload from 'js-file-download';
import Api from 'util/Api';
import 'history';
import { notification, PageHeader } from 'antd';

const PackageView = () => {
  const [loading, setLoading] = useState(true);
  const [packet, setPacket] = useState(null);
  const [paquer, setPaquer] = useState(null);
  const [error, setError] = useState('');
  const countries = useSelector(selectCountries);
  const packageStatuses = usePackageStatus();
  const deliveryTerms = useDeliveryTerms();
  const packageSizes = usePackageSizes();
  const history = useHistory();

  const { id } = useParams();

  const handleReceiptDownload = useCallback(async () => {
    if (packet) {
      const response = await Api.get(packet.remitoUrl, {
        responseType: 'blob',
      });
      if (!Api.isSuccessResponse(response)) {
        const blob = response.data;
        const errorMessage = await blob.text();
        notification.error({
          message: 'No se pudo descargar el remito',
          description: errorMessage || 'Ha ocurrido un error en el servidor.',
        });
        return;
      }

      JsDownload(response.data, `${packet.externalCode}.pdf`);
    }
  }, [packet]);

  const handleFetchPacket = useCallback(async () => {
    setLoading(true);
    const response = await fetchPackage(id);
    setLoading(false);
    if (response instanceof Error) setError(response.message);
    setPacket(response);
  }, [id]);

  const handleFetchPaquer = useCallback(async () => {
    const response = await fetchPaquer(
      packet.shippingScheduleDestination.driver.id,
    );
    if (!(response instanceof Error)) setPaquer(response);
  }, [packet]);

  // Fetch the package
  useEffect(() => {
    handleFetchPacket();
  }, [handleFetchPacket]);

  // If the package was fetched, fetch the paquer
  useEffect(() => {
    if (packet?.shippingScheduleDestination?.driver?.id) {
      handleFetchPaquer();
    }
  }, [handleFetchPaquer, packet]);

  return (
    <PageHeader onBack={() => history.goBack()} title="Estado del pedido">
      <PackageViewComponent
        packet={packet}
        loading={loading}
        errorMessage={error}
        paquer={paquer}
        countries={countries}
        packageStatuses={packageStatuses}
        deliveryTerms={deliveryTerms}
        packageSizes={packageSizes}
        onReceiptDownload={handleReceiptDownload}
      />
    </PageHeader>
  );
};

export default PackageView;
