import React from 'react';
import moment from 'moment';

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text, record) => {
    // ¿Por que no usamos reactJSS aca?
    // Las columns estan fuera del componente, no se tiene acceso a classes.
    // Inicializar el hook de estilos por cada fila de la tabla es poco performante
    // Usamos estilos fijos en el src/index.css en esta ocasion
    // Alternativas:
    // - meter las columns dentro del componente
    // - hacer hook de columnas que inicialice estilos de reactjss
    let expiredPackage;
    let valuablePackage;
    const { scheduledDate } = record.shippingScheduleDestination;
    if (scheduledDate && moment().isAfter(scheduledDate)) {
      expiredPackage = (
        <div
          className="expiredPackage tooltip-message"
          data-message="Vencido"
        />
      );
    }
    return (
      <span style={{ position: 'relative', wordBreak: 'break-word' }}>
        <div className="packageNotificationContainer">
          {expiredPackage}
          {valuablePackage}
        </div>
        {text}
      </span>
    );
  },
};

export default externalCodeColumn;
