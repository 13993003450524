import React from 'react';
import Report from 'components/report';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { authProfile } from 'redux/auth/selectors';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Dashboard = () => {
  const profile = useSelector(authProfile);
  if (!profile) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin indicator={antIcon} tip="Cargando estadisticas..." spinning />
      </div>
    );
  }
  return (
    <Report slug="Paquery_Gerencial_Produccion/Gerencial" parameters={{}} />
  );
};

export default Dashboard;
