import React, { useCallback, useEffect, useState } from 'react';
import { PackagesStatus, TableDateTimeFormat } from 'default';
import { notification } from 'antd';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import usePackageHistory from 'redux/packagesHistory/index';
import globalSelector from 'redux/globals/selectors';
import {
  packagesHistoryItemsSelector,
  packagesHistoryPaginationSelector,
  packagesHistoryLoadedSelector,
  packagesHistoryDateSelector,
  packagesHistorySearchSelector,
  packagesHistoryStatusSelector,
  packagesHistoryTypeSelector,
  packagesMarketFilterSelector,
} from 'redux/packagesHistory/selectors';
import { actions } from 'redux/packagesHistory/slice';
import { fetchPackagesForCsv } from 'redux/packagesHistory/saga';
import moment from 'moment';
import PaqueryTable from '@paquery-team/lib-table';
import JsDownload from 'js-file-download';
import { marketplacesItemsSelector } from 'redux/marketplaces/selectors';
import { actions as marketplaceActions } from 'redux/marketplaces/slice';
import useMarketplaces from 'redux/marketplaces';
import useMarketplaceOptions from 'app/hooks/useMarketplaceOptions';
import ViewPackageButton from 'components/viewPackageButton';

const statusStyle = (statusText) => {
  let style = {
    background: 'gray',
    padding: 4,
    borderRadius: '5px',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    color: 'white',
  };
  switch (statusText) {
    case 'Entregado':
      style.background = 'green';
      break;
    case 'Cancelado':
      style.background = 'red';
      break;
    case 'Devuelto':
      style.background = 'gold';
      break;
    case 'Siniestrado':
      style.background = 'orange';
      break;
    default:
      style = {};
      break;
  }
  return style;
};

const externalCodeColumn = {
  title: 'Externo',
  dataIndex: 'externalCode',
  width: 130,
  render: (text) => <span style={{ wordBreak: 'break-word' }}>{text}</span>,
};

const destinationAddressColumn = {
  title: 'Destino',
  dataIndex: 'destinationAddress',
};

const deliveryTermColumn = {
  title: 'Plazo',
  dataIndex: 'deliveryTerm',
};

const contentColumn = {
  title: 'Contenido',
  dataIndex: 'content',
};

const arrivedAtPaqueryPointDateColumn = {
  title: 'Arribó a PaqueryPoint',
  dataIndex: 'arrivedAtPaqueryPointDate',
  align: 'center',
};

const deliveryDateColumn = {
  title: 'Fecha de entrega',
  dataIndex: 'deliveryDate',
};

const signatureNameColumn = {
  title: 'Firma',
  dataIndex: 'signatureName',
};

const statusColumn = {
  title: 'Estado',
  dataIndex: 'statusDescription',
  render: (text) => <span style={statusStyle(text)}>{text}</span>,
};

const viewPackageColumn = {
  align: 'center',
  render: (_, record) => <ViewPackageButton packet={record} />,
};

const defaultPaginate = {
  pageSize: 10,
  pageNumber: 0,
  totalElements: 0,
};

const dataColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  signatureNameColumn,
  statusColumn,
];

const fullSizeColumns = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  contentColumn,
  arrivedAtPaqueryPointDateColumn,
  deliveryDateColumn,
  statusColumn,
  viewPackageColumn,
];

const columnsSmallDevice = [externalCodeColumn, viewPackageColumn];

const columnsMediumDevice = [
  externalCodeColumn,
  destinationAddressColumn,
  deliveryTermColumn,
  viewPackageColumn,
];

const PackageHistory = () => {
  usePackageHistory();
  useMarketplaces();
  const dispatch = useDispatch();
  const globals = useSelector(globalSelector(), shallowEqual);
  const loaded = useSelector(packagesHistoryLoadedSelector);
  const date = useSelector(packagesHistoryDateSelector);
  const packageType = useSelector(packagesHistoryTypeSelector);
  const search = useSelector(packagesHistorySearchSelector);
  const status = useSelector(packagesHistoryStatusSelector);
  const items = useSelector(packagesHistoryItemsSelector);
  const pageable = useSelector(packagesHistoryPaginationSelector);
  const marketplaces = useSelector(marketplacesItemsSelector);
  const marketplace = useSelector(packagesMarketFilterSelector);
  const [disabled, setDisabled] = useState(false);

  const marketplacesOptions = useMarketplaceOptions(marketplaces);

  const historyStatus = PackagesStatus.historyStatus.map((statusDescription) =>
    globals.packages.status.find(
      (statusApi) => statusApi.name === statusDescription,
    ),
  );

  useEffect(() => {
    dispatch(marketplaceActions.initial());
  }, [dispatch]);

  const handleExportCsv = async () => {
    setDisabled(true);
    try {
      const csv = await fetchPackagesForCsv(
        dispatch,
        search,
        marketplace,
        packageType,
        status,
        date,
      );
      const filename = `Paquetes-Historial-${moment()
        .format('DD-MM-YYYY HH_mm_ss')
        .toString()}.xls`;
      JsDownload(csv, filename);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      notification.error({
        message: 'Ha ocurrido un error al traer los paquetes',
        description:
          'Intente nuevamente por favor o informelo a los administradores',
      });
    } finally {
      setDisabled(false);
    }
  };

  const searchCallback = useCallback(
    (value) => {
      dispatch(actions.updateSearch(value));
    },
    [dispatch],
  );
  const dateSelectionCallback = useCallback(
    (value) => {
      dispatch(actions.updateDate(value));
    },
    [dispatch],
  );
  const selectStatusCallback = useCallback(
    (value) => {
      dispatch(actions.updateStatus(value));
    },
    [dispatch],
  );
  const selectMarketplaceCallback = useCallback(
    (value) => {
      dispatch(actions.updateMarketplace(value));
    },
    [dispatch],
  );

  const searchers = [
    {
      onSearching: searchCallback,
      defaultValue: search,
      placeholder: 'Buscar cod. o destinatario',
    },
  ];
  const selectors = [
    {
      list: historyStatus,
      defaultValue: status,
      onChange: selectStatusCallback,
      placeholder: 'Seleccione estado',
      showAll: true,
    },
    {
      list: marketplacesOptions,
      defaultValue: '',
      onChange: selectMarketplaceCallback,
      placeholder: 'Seleccione marketplace',
      showAll: true,
    },
  ];

  let filteredPackages = [];
  if (loaded && items) {
    filteredPackages = items.map((packet) => ({
      ...packet,
      key: packet.id,
      externalCode: packet.externalCode,
      destinationAddress:
        packet.shippingScheduleDestination.shippingAddress.addressDetail,
      deliveryTerm: globals.deliveryTerm.find(
        (term) => term.value === packet.deliveryTerm,
      ).description,
      content: packet.caption,
      deliveryDate: packet.deliveryDate
        ? moment(packet.deliveryDate).format(TableDateTimeFormat)
        : null,
      arrivedAtPaqueryPointDate: packet.arrivedAtPaqueryPointDate
        ? moment(packet.arrivedAtPaqueryPointDate).format(TableDateTimeFormat)
        : null,
      statusDescription: globals.packages.status.find(
        (state) => state.value === packet.status,
      ).name,
    }));
  }

  return (
    <PaqueryTable
      loading={!loaded}
      header={{
        searchers,
        selectors,
        dateItems: {
          onDateSelection: dateSelectionCallback,
          required: {
            value: true,
            message: 'La fecha es requerida',
          },
        },
        refresh: () => dispatch(actions.refreshPage()),
        onExportCsv: { callback: handleExportCsv, disabled },
      }}
      usePackageRowColors
      onChangePaginate={(values) => dispatch(actions.updatePagination(values))}
      dataSource={filteredPackages}
      paginate={pageable || defaultPaginate}
      dataColumns={dataColumns}
      colsForMediumDevice={columnsMediumDevice}
      colsForSmallDevice={columnsSmallDevice}
      colsForLargeDevice={fullSizeColumns}
    />
  );
};

export default PackageHistory;
