import React from 'react';
import PropTypes from 'prop-types';
import TableauReport from 'tableau-react';
import { useGetData } from 'use-axios-react';
import { Button, Spin } from 'antd';
import Media from 'react-media';
import { createUseStyles } from 'react-jss';
import { LoadingOutlined } from '@ant-design/icons';

function TableauReportResponsive({ children }) {
  return (
    <Media
      queries={{
        small: '(max-width: 599px)',
        medium: '(min-width: 600px) and (max-width: 1199px)',
        large: '(min-width: 1200px)',
        // large: '(min-width: 600px)',
      }}
    >
      {(matches) => {
        let options = null;
        if (matches.large) options = { height: 800, width: 1200 };
        else if (matches.medium) options = { height: 600, width: 600 };
        else options = { height: 600, width: 476 };
        return children({
          options,
          refreshForDimensions: `${options.height}x${options.width}`,
        });
      }}
    </Media>
  );
}

TableauReportResponsive.propTypes = {
  children: PropTypes.func.isRequired,
};

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const TableauReportCustomized = (props) => {
  const [data, loading, { error, retry }] = useGetData(
    'https://ttoken.qualesgroup.com/getTicket/Clientes',
  );
  if (loading)
    return (
      <div
        style={{
          width: '100%',
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin indicator={antIcon} tip="Cargando estadisticas..." spinning />
      </div>
    );
  if (error) {
    return (
      <Button outline block onClick={retry}>
        Reintentar
      </Button>
    );
  }
  return <TableauReport {...props} token={data.ticket} />;
};
const Logic = ({ slug, parameters }) => (
  <TableauReportResponsive>
    {({ options, refreshForDimensions }) => (
      <TableauReportCustomized
        key={refreshForDimensions}
        url={`https://tt.qualesgroup.com/t/Clientes/views/${slug}?refreshForDimensions${refreshForDimensions}`}
        parameters={parameters}
        options={options}
        query="&:embed=yes&:comments=no&:toolbar=no&:refresh=yes"
      />
    )}
  </TableauReportResponsive>
);

Logic.propTypes = {
  slug: PropTypes.string.isRequired,
  parameters: PropTypes.shape({}).isRequired,
};

const useStyles = createUseStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
    },
    '& iframe': {
      width: '100% !important',
    },
  },
});

const Layout = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  parameters: PropTypes.shape({}).isRequired,
};

const Report = ({ slug, parameters }) => (
  <Layout>
    <Logic slug={slug} parameters={parameters} />
  </Layout>
);

Report.propTypes = {
  slug: PropTypes.string.isRequired,
  parameters: PropTypes.shape({}).isRequired,
};

export default Report;
