import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { API } from 'default';
import { getData } from 'util/Api';
import { actions } from './slice';
import { paquersItemsSelector } from './selectors';

function* fetchSaga() {
  try {
    const searchParams = new URLSearchParams({
      size: 10000,
      search: '',
    });
    const paquers = yield call(
      getData,
      `${API.paquer.get}?${searchParams.toString()}`,
    );
    yield put({ type: actions.success.type, payload: paquers.content });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* initialSaga() {
  const items = yield select(paquersItemsSelector);
  if (!items || items.length === 0) {
    yield put({ type: actions.fetch.type });
  }
}

function* refreshPageSaga() {
  yield put({ type: actions.fetch.type });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.initial, initialSaga),
    takeEvery(actions.fetch, fetchSaga),
    takeEvery(actions.refreshPage, refreshPageSaga),
  ]);
}
