import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import { Formik, Form } from 'formik';
import { LOGIN } from 'paquery-language/keys';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import axios from 'axios';
import PropTypes from 'prop-types';
import useStyles from '../styles';
import LoginInput from './Input';

const TranslateKeys = LOGIN.RECOVERY;

const { Text } = Typography;

const initialValues = {
  email: '',
};

const RecoverPassword = ({ onSubmit, URL }) => {
  const [t] = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t(TranslateKeys.VALIDATION.EMAILREQUIRED))
      .required(t(TranslateKeys.VALIDATION.NOTEMPTY)),
  });

  const displayError = (message) => {
    setError(<Text className={classes.label}>{message}</Text>);
    setTimeout(() => {
      setError(null);
    }, 1500);
    setLoading(false);
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await axios.post(`${URL}${values.email}`);
      history.push('/confirmRecovery');
    } catch (ex) {
      displayError(t(TranslateKeys.MESSAGES.EMAILNOTFOUND));
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit || handleSubmit}
      >
        <Form>
          <Text className={classes.label}>
            {t(TranslateKeys.MESSAGES.RECOVERYMESSAGE)}
          </Text>
          <LoginInput
            type="email"
            name="email"
            label={t(TranslateKeys.LABEL.EMAIL)}
            placeholder={t(TranslateKeys.LABEL.EMAIL)}
          />
          <div style={{ height: 30, marginTop: 10 }}>{error}</div>
          <Button
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            className={`${classes.submitButton} ${classes.rounded}`}
          >
            {t(TranslateKeys.SUBMIT)}
          </Button>
        </Form>
      </Formik>
    </>
  );
};

RecoverPassword.propTypes = {
  onSubmit: PropTypes.func,
  URL: PropTypes.string.isRequired,
};

RecoverPassword.defaultProps = {
  onSubmit: null,
};

export default RecoverPassword;
