import React from 'react';
import { ContainerOutlined, FundViewOutlined } from '@ant-design/icons';
import { SITELINKS } from 'default';

const TENANT_MENU = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    icon: <FundViewOutlined />,
    link: SITELINKS.dashboard,
  },
  {
    key: 'reports',
    title: 'Reportes',
    icon: <ContainerOutlined />,
    subMenuItems: [
      {
        key: 'packageHistory',
        title: 'Histórico',
        link: SITELINKS.packages.history,
      },
      {
        key: 'packagesByPaquer',
        title: 'Paquetes por paquer',
        link: SITELINKS.paquers.packagesBy,
      },
      {
        key: 'packagesHistoricalByPaquer',
        title: 'Histórico por paquer',
        link: SITELINKS.paquers.packagesHistorical,
      },
    ],
  },
];

export default function useMenu() {
  return TENANT_MENU;
}
