import { createUseStyles } from 'react-jss';

const paqueryGreen = '#0db8b2';
const paqueryGreenBorder = '#0ec9c3';

export default createUseStyles({
  versionLangContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonLang: (props) => ({
    margin: '0 5px',
    backgroundColor: 'transparent',
    fontSize: '100%',
    fontFamily: 'inherit',
    border: `1px solid ${paqueryGreenBorder}`,
    borderRadius: '50%',
    padding: 0,
    background: `url(${props?.icon})`,
    backgroundPosition: 'center center',
    backgroundSize: '150%',
    width: 32,
    height: 32,
    transition: '400ms all linear',
    '&:hover': {
      boxShadow: `0px 0px 4px 2px ${paqueryGreen}`,
    },
  }),
  buttonSelected: {
    boxShadow: `0px 0px 2px 1px ${paqueryGreen}`,
  },
});
