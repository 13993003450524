import { createSlice } from '@reduxjs/toolkit';
import { clearReducers } from 'redux/logout';
import { removePacket } from 'redux/packageAll/actions';

export const initialState = {
  items: {},
  search: '',
  pageable: {
    pageNumber: 0,
    pageSize: 10,
  },
  loaded: false,
};

const paquersSlice = createSlice({
  name: 'paquers',
  initialState,
  reducers: {
    initial: () => {},
    fetch: (state) => {
      state.loaded = false;
    },
    success: (state, action) => {
      state.loaded = true;
      action.payload.forEach((paquer) => {
        state.items[paquer.id] = paquer;
      });
    },
    updateItems: (state, action) => {
      state.items[action.payload.id] = action.payload;
    },
    removePaquer: (state, action) => {
      delete state.items[action.payload];
    },
    failure: (state) => {
      state.loaded = false;
    },
    updatePageable: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    updateSearch: (state, action) => {
      state.pageable.pageNumber = 0;
      state.search = action.payload;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.pageable = initialState.pageable;
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
    [removePacket]: (state, action) => {
      delete state.items[action.payload];
    },
  },
});

export const { name, reducer, actions } = paquersSlice;