import { createUseStyles } from 'react-jss';
import { WebStyle } from 'default';

const center = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default createUseStyles({
  center,
  modalHeaderFooter: {
    ...center,
    height: 45,
    background: WebStyle.paqueryGreen,
    color: WebStyle.paqueryFontColor,
    fontSize: '1.3rem',
    padding: 4,
    fontWeight: 'bold',
  },
  modalButton: {
    width: '100%',
    background: WebStyle.paqueryGreen,
    color: WebStyle.paqueryFontColor,
    borderWidth: 2,
    borderColor: WebStyle.paqueryFontColor,
  },
});
