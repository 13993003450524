import { GLOBALS } from 'paquery-language/keys';
import useGetValueNameArray from './utils';

const keys = [
  {
    name: GLOBALS.PACKAGE.STATUS.PENDINGTOADDPAQUERY,
    value: 1,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.ARRIVEDTOPOINT,
    value: 2,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.INHANDSOFPAQUER,
    value: 3,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.ONTHEWAY,
    value: 4,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.PENDINGTOPROGRAM,
    value: 5,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.ACCEPTEDBYPAQUER,
    value: 7,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.DELIVEREDPENDINGSIGNATURE,
    value: 19,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.DELIVERED,
    value: 20,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.CANCELLED,
    value: 21,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.ARRIVEDTOPAQUERY,
    value: 22,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.ONTHEWAYOFPAQUERYPOINT,
    value: 23,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.AVAILABLEONSTORE,
    value: 24,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.WRONGADDRESS,
    value: 25,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.UNABLETODELIVERONZONE,
    value: 26,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.DELIVERYCONTINGENCY,
    value: 27,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.DAMAGED,
    value: 28,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.PENDINGPAYMENT,
    value: 30,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.DELIVERYTRYONE,
    value: 31,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.DELIVERYTRYTWO,
    value: 32,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.RETURNING,
    value: 39,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.RETURNED,
    value: 40,
  },
  {
    name: GLOBALS.PACKAGE.STATUS.EXPIRED,
    value: 50,
  },
];
export default () => useGetValueNameArray(keys);
