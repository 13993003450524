import moment from 'moment';

const baseUrl = `${process.env.REACT_APP_API_URL}/api`;
const msApiUrl = `${process.env.REACT_APP_MS_API_URL}`;
const wsUrl = msApiUrl.replace('https://', '');
const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';

export const getCaronteBasicAuth = () => {
  const caronteUsername = process.env.REACT_APP_CARONTE_USERNAME;
  const carontePassword = process.env.REACT_APP_CARONTE_PASSWORD;
  if (!caronteUsername || caronteUsername === '') {
    const error = new Error('El usuario caronte no esta configurado');
    // eslint-disable-next-line
    console.log(error.message);
    throw error;
  }
  if (!carontePassword || carontePassword === '') {
    const error = new Error('La contraseña caronte no esta configurada');
    // eslint-disable-next-line
    console.log(error.message);
    throw error;
  }
  const authBuffer = Buffer.from(`${caronteUsername}:${carontePassword}`);
  const authBase64 = authBuffer.toString('base64');
  return `Basic ${authBase64}`;
};

export const MENU_DISABLE_DASHBOARD =
  process.env.REACT_APP_DISABLE_DASHBOARD === 'true';

export const paqueryContactUrl =
  process.env.REACT_APP_PAQUERY_CONTACT_SUPPORT_URL;

export const ENABLE_WEBSOCKETS = process.env.NODE_ENV === 'production';

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const imageRegExp = /data:(.*);base64,/;

export const GeoCodeParameters = {
  app_id: 'MBtttw0ilTLGyGoskMgB',
  app_code: 'e7fgY70B6UbgzM0DejwUSw',
  resultType: 'houseNumber',
  maxresults: '20',
  language: 'es',
};

export const API = {
  auth: {
    login: `${process.env.REACT_APP_API_URL}/apptoken`,
    recover: `${baseUrl}/customeradmin/sendrecoverypassword?Email=`,
    confirm: `${baseUrl}/customeradmin/recoverypassword`,
  },
  profile: {
    load: `${baseUrl}/profile/load`,
  },
  globals: {
    packageType: `${msApiUrl}/packages/parametrics/packageType`,
    packageSize: `${msApiUrl}/packages/parametrics/packageSize`,
    packageStatus: `${msApiUrl}/packages/parametrics/packageStatus`,
    deliveryPackageType: `${msApiUrl}/packages/parametrics/deliveryPackageType`,
    deliveryTerm: `${msApiUrl}/packages/parametrics/deliveryTerm`,
    notifierType: `${msApiUrl}/mkp/parametrics/notifierType`,
    countries: `${baseUrl}/geolocation/loadcountries`,
    cities: `${baseUrl}/geolocation/loadcities`,
    roles: `${baseUrl}/apptype/userrole`,
    logisticOperator: `${msApiUrl}/opl/logisticoperator?page=0&size=1000`,
    userType: `${baseUrl}/apptype/usertype`,
    daysOfWeek: `${msApiUrl}/pqpoint/paquerypoint/daysOfWeek`,
    currentServices: `${msApiUrl}/pqpoint/paquerypoint/currentServices`,
  },
  marketplaces: {
    get: `${msApiUrl}/mkp/marketplace`,
  },
  massive: {
    list: `${msApiUrl}/packages/massive`,
    packagesUpload: (userId) =>
      `${msApiUrl}/caronte/integration/${userId}/massive`,
    packagesDownload: '/assets/massiveAdd/PaQuery-Import.xlsx',
    routesUpload: `${msApiUrl}/packages/packages/routeMassive`,
    routesDownload: '/assets/routes/PaQuery-Import.xlsx',
  },
  packages: {
    withPaquers: `${msApiUrl}/packages/packages/withPaquers`,
    get: `${msApiUrl}/packages/packages`,
    getById: `${msApiUrl}/packages/packages/`,
    history: `${msApiUrl}/packages/packages/history`,
    send: `${msApiUrl}/packages/packages`,
    getByExternalCode: `${msApiUrl}/packages/packages/findByExternalCode/`,
    massiveAddDownload: '/assets/massiveAdd/PaQuery-Import.xlsx',
    massiveAddUpload: `${baseUrl}/masive/upload`,
    label: `${msApiUrl}/labels/packages/`,
    labelByMassive: (code) => `${msApiUrl}/labels/packages/massive/${code}`,
    getLabels: `${msApiUrl}/labels/packages/packagesLabels/`,
    getNormalByStoreId: (storeId) =>
      `${msApiUrl}/packages/packages/packagesByStoreId/${storeId}`,
    getHistoryByStoreId: (storeId) =>
      `${msApiUrl}/packages/packages/packagesHistoryByStoreId/${storeId}`,
    getByMassiveCode: `${msApiUrl}/packages/massive/packages/list`,
    downloadDeliveryNoteById: (id) =>
      `${baseUrl}/packageadmin/generateremito?packageID=${id}`,
  },
  storeWithdrawal: {
    get: `${msApiUrl}/packages/packages/storeWithdrawal`,
    history: `${msApiUrl}/packages/packages/storeWithdrawal/history`,
    massiveAddUpload: (userId) =>
      `${msApiUrl}/caronte/integration/${userId}/massive`,
  },
  stores: {
    get: `${msApiUrl}/mkp/store`,
    create: `${msApiUrl}/mkp/store`,
  },
  websocket: {
    url: `${wsProtocol}://${wsUrl}/packages/socket/websocket`,
    packages: (marketId) => `/topic/marketplace.${marketId}.packages.update`,
  },
  zones: {
    // esto es auxiliar, devuelve las zonas viejas y nuevas por id's
    getById: `${msApiUrl}/routing/geojson/load-zones-byId`,
    getAllWithGeoJson: `${msApiUrl}/routing/geojson/load-zones/1`,
    getAll: `${msApiUrl}/routing/geojson/load-zones/0`,
    resolve: `${msApiUrl}/entry/paquery/resolveZone`,
    delete: `${msApiUrl}/routing/geojson/delete-zone`,
    add: `${msApiUrl}/routing/geojson/create-zone`,
    update: `${msApiUrl}/routing/geojson/update-zone`,
  },
  geocode: {
    get: 'https://autocomplete.geocoder.api.here.com/6.2/suggest.json?',
    w3w: `${baseUrl}/integration/getw3wbyaddress/?`,
    resolveZone: `${msApiUrl}/maps/zones/resolveZone`,
    findZone: (searchOrPostalCode) =>
      `${msApiUrl}/maps/zones/findZone?search=${searchOrPostalCode}`,
  },
  user: {
    create: `${baseUrl}/customeradmin/create`,
    getAll: `${baseUrl}/customeradmin/getall/`,
    get: `${baseUrl}/customeradmin/get?id=`,
    update: `${baseUrl}/customeradmin/update`,
    delete: `${baseUrl}/customeradmin/delete?id=`,
    changePassword: `${baseUrl}/customeradmin/sendrecoverypassword`,
    changeUserPassword: `${baseUrl}/UsersCommonAdmin/changepassword`,
  },
  paquer: {
    get: `${msApiUrl}/pqr/paquer/`,
  },
  reports: {
    packages: `${msApiUrl}/reports/report`,
    history: `${msApiUrl}/reports/report/history`,
    storePackages: `${msApiUrl}/reports/report/store`,
    storeHistoryPackages: `${msApiUrl}/reports/report/store/history`,
    packagesbyPaquer: `${msApiUrl}/reports/report/packagesWithPaquers`,
    historyByPaquer: `${msApiUrl}/reports/report/paquer/history`,
  },
  whatsapp: {
    sendMessage: 'https://web.whatsapp.com/send?phone=',
  },
};

export const SITELINKS = {
  profiles: '/profile',
  dashboard: '/dashboard',
  login: {
    index: '/login',
    recover: '/recoverPassword',
    confirm: '/confirmRecovery',
  },
  paquers: {
    packagesHistorical: '/packagesHistoricalByPaquer',
    packagesBy: '/packagesByPaquer',
  },
  packages: {
    history: '/packagesHistory',
    view: '/packages/view/:id',
  },
  error: '/error',
};

export const haveDateLoaded = (date) => date.from !== '' && date.to !== '';

export const FILTER_FOR_PACKAGE_TYPES = [
  {
    name: 'Enviar',
    value: 2,
  },
  {
    name: 'Retiro en Tienda',
    value: 4,
  },
  {
    name: 'Flex',
    value: 5,
  },
  {
    name: 'Pickup',
    value: 3,
  },
];

export const prefixRegExp =
  /(?:\+)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/;

export const DEFAULT_PACKAGE_TYPE_FILTER = 2;

export const SOCKET_RETRY_INTERVAL_MS = 1000;

export const PackagesStatus = {
  readyForDeliveryStatus: ['Arribado a PaQuery Point', 'Disponible en Tienda'],
  historyStatus: ['Entregado', 'Cancelado', 'Devuelto', 'Siniestrado'],
  contactPaquerStatus: [3, 7, 31, 32],
  paqueryPointStatus: 22,
  deliveredStatus: 20,
};

export const defaultPaginate = {
  pageNumber: 0,
  pageSize: 10,
  totalElements: 0,
};

export const TableDateTimeFormat = 'DD/MM/YY, HH:mm';
export const TableDateFormat = 'DD/MM/YY';
export const ApiDateFilterFormat = 'YYYY-MM-DD';

export const defaultDateFilterPackageHistory = {
  from: moment().subtract(1, 'week').format(ApiDateFilterFormat).toString(),
  to: moment().format(ApiDateFilterFormat).toString(),
};

export const defaultDateFilterPackageHistoryStore = {
  from: moment().subtract(1, 'week').format(ApiDateFilterFormat).toString(),
  to: moment().format(ApiDateFilterFormat).toString(),
};

export const defaultStatusFilterPackage = ''; // Todos

export const defaultStatusFilterStorePackage = 24; // Disponible en tienda

export const MARKETPLACE_ROLES = [5, 6];
export const APP_ROLE_MARKETPLACE = 'MARKETPLACE_USER';
export const APP_ROLE_STORE = 'STORE_USER';
export const STORE_ROLES = [11, 12];
export const OPL_ROLES = [3, 4];
export const ADMIN_ROLES = [1];
export const SHOP_OWNERTYPE = 20;

// FIXME: esto es temporal, solo debe poder iniciar sesion el administrador
export const ALLOWEDROLES = [...ADMIN_ROLES, ...OPL_ROLES];

export const PACKAGE_TYPE_FOR_STANDARD_LIST = 2;

export const PACKAGE_TYPE_FOR_STORE_WITHDRAWL_LIST = 4;

export const ALL_STATUS_FILTERING = 'ALL_STATUS';

export const ALL_ROLES_FILTERING = 'ALL_ROLES';

export const STARTING_LOAD = 'STARTING_LOAD';

const paqueryGreen = '#0db8b2';

export const AttemptStatuses = [
  {
    id: 31,
    numberVisit: 1,
  },
  {
    id: 32,
    numberVisit: 2,
  },
];

export const WebStyle = {
  actionButtons: {
    fontSize: '1.5rem',
    color: paqueryGreen,
  },

  addButton: {
    color: '#fff',
    background: paqueryGreen,
    border: '0',
    padding: '4px 6px',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: '#60ebe4',
      color: '#fff',
    },
    '&:disabled': {
      background: '#cccccc',
      cursor: 'default',
    },
  },
  paqueryGreen,
  paqueryGreenBorder: '#0ec9c3',
  paqueryBackgroundColor: '#254370',
  paqueryFontColor: '#fff',
  paqueryBgContentColor: '#fff',
  paqueryBgLayoutColor: '#f0f2f5',
};

export const otherNotifiarType = Object.freeze({ label: 'Otra', value: 0 });
export default {};
