import { GLOBALS } from 'paquery-language/keys';
import useGetValueNameArray from './utils';

const keys = [
  {
    name: GLOBALS.DELIVERYTERM.FOURTYEIGHTHOURS,
    value: 1,
  },
  {
    name: GLOBALS.DELIVERYTERM.NEXTDAY,
    value: 2,
  },
  {
    name: GLOBALS.DELIVERYTERM.SAMEDAY,
    value: 3,
  },
  {
    name: GLOBALS.DELIVERYTERM.NEXTTWOHOUR,
    value: 4,
  },
  {
    name: GLOBALS.DELIVERYTERM.NEXTWEEK,
    value: 5,
  },
];
export default () => useGetValueNameArray(keys);
