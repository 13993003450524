import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { getData } from 'util/Api';
import { API } from 'default';
import { notification } from 'antd';
import { logout } from '../logout';
import { actions } from './slice';
import { selectCountries } from './selectors';

function* getCountryCities(country) {
  const cities = {
    countryID: country,
    items: yield call(getData, `${API.globals.cities}/${country}`),
  };
  return cities;
}

function* fetchCities() {
  const countries = yield select(selectCountries);
  try {
    const cities = yield all(
      countries.map((country) => call(getCountryCities, country.id)),
    );
    yield put({ type: actions.fetchCitiesSuccess.type, payload: cities });
  } catch (error) {
    console.log(error);
  }
}

function* fetchGlobalsSaga() {
  try {
    const {
      packageType,
      packageSize,
      packageStatus,
      deliveryPackageType,
      countries,
      roles,
      deliveryTerm,
      notifierType,
      logisticOperator,
      userType,
    } = yield all({
      packageType: call(getData, API.globals.packageType),
      packageSize: call(getData, API.globals.packageSize),
      packageStatus: call(getData, API.globals.packageStatus),
      deliveryPackageType: call(getData, API.globals.deliveryPackageType),
      notifierType: call(getData, API.globals.notifierType),
      countries: call(getData, API.globals.countries),
      roles: call(getData, API.globals.roles),
      deliveryTerm: call(getData, API.globals.deliveryTerm),
      logisticOperator: call(getData, API.globals.logisticOperator),
      userType: call(getData, API.globals.userType),
    });
    const packages = {
      type: packageType,
      size: packageSize,
      status: packageStatus,
      deliveryEndorsementType: deliveryPackageType,
    };
    const globals = {
      packages,
      countries,
      roles,
      deliveryTerm,
      notifierType,
      logisticOperator,
      userType,
    };
    yield put({ type: actions.success.type, payload: globals });
  } catch (error) {
    notification.error({
      message: 'Error iniciando la aplicacion',
      description: `Hubo un error llamando a los servicios, intente nuevamente o contacte a soporte - ${error.message}`,
      duration: 20,
    });
    yield put({ type: logout.type });
    yield put({ type: actions.failure.type });
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.fetch.type, fetchGlobalsSaga);
  yield takeLatest(actions.success.type, fetchCities);
}
