import { createSlice } from '@reduxjs/toolkit';
import { removePacket } from 'redux/packageAll/actions';
import { clearReducers } from '../logout';

export const initialState = {
  items: [],
  loaded: true,
  pageable: {
    pageSize: 10,
    pageNumber: 0,
    totalElements: 0,
  },
  marketplaceId: '',
  search: '',
  status: '',
  paquerId: '',
  type: '',
  date: {
    from: '',
    to: '',
  },
};

const packagesWithPaquersSlice = createSlice({
  name: 'packagesWithPaquers',
  initialState,
  reducers: {
    fetch: (state) => {
      state.loaded = false;
    },
    fetchSuccess: (state, action) => {
      state.items = action.payload.items;
      state.pageable.totalElements = action.payload.total;
      state.loaded = true;
    },
    fetchFailure: (state) => {
      state.loaded = true;
    },
    updatePageable: (state, action) => {
      state.pageable.pageNumber =
        action.payload.pageSize !== state.pageable.pageSize
          ? 0
          : action.payload.pageNumber;
      state.pageable.pageSize = action.payload.pageSize;
    },
    fetchForCsv: (state) => {
      state.loaded = false;
    },
    fetchForCsvSuccess: (state, action) => {
      state.csvItems = action.payload;
      state.loaded = true;
    },
    fetchForCsvFailure: (state) => {
      state.loaded = true;
    },
    fetchOnePacket: (state) => {
      state.loaded = false;
    },
    fetchOnePacketSuccess: (state) => {
      state.loaded = true;
    },
    fetchOnePacketFailure: (state) => {
      state.loaded = true;
    },
    updatePacket: () => {},
    updateSearch: (state, action) => {
      state.pageable.pageNumber = 0;
      state.search = action.payload;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateDate: (state, action) => {
      state.date = action.payload;
      state.pageable.pageNumber = 0;
    },
    updateMarket: (state, action) => {
      state.pageable.pageNumber = 0;
      state.marketplaceId = action.payload;
    },
    updatePaquerId: (state, action) => {
      state.paquerId = action.payload;
    },
    updatePackageType: (state, action) => {
      state.type = action.payload;
    },
    updatePackagePaquer: (state, action) => {
      state.items[action.payload.packageId].shippingScheduleOrigin.driver =
        action.payload.selectedPaquer;
    },
    removePacket: (state, action) => {
      delete state.items[action.payload];
    },
    loading: (state) => {
      state.loaded = false;
    },
    loaded: (state) => {
      state.loaded = true;
    },
    resetFilters: (state) => {
      state.search = initialState.search;
      state.pageable = initialState.pageable;
    },
    refreshPage: () => {},
  },
  extraReducers: {
    [clearReducers]: () => initialState,
    [removePacket]: (state, action) => {
      delete state.items[action.payload];
    },
  },
});

export const { name, actions, reducer } = packagesWithPaquersSlice;
