import React from 'react';
import { Button, Typography } from 'antd';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { LOGIN } from '@paquery-team/lib-paquery-language/keys';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import LangSelector from 'components/LangSelector/LangSelector';
import PropTypes from 'prop-types';
import useStyles from '../styles';
import LoginInput from './Input';

const TranslateKeys = LOGIN.MAIN;

const { Text } = Typography;

const initialValues = {
  email: '',
  password: '',
};

const Main = ({ onSubmit, isLogginIn, loginFailure }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t(TranslateKeys.VALIDATION.EMAILREQUIRED))
      .required(t(TranslateKeys.VALIDATION.EMAILNOTEMPY)),
    password: Yup.string().required(
      t(TranslateKeys.VALIDATION.PASSWORDNOTEMPTY),
    ),
  });

  let loginFailureComponent;
  if (loginFailure) {
    loginFailureComponent = (
      <Text className={classes.label}>
        {t(TranslateKeys.MESSAGE.LOGININVALID)}
      </Text>
    );
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={loginSchema}
      >
        <Form>
          <LoginInput
            type="email"
            name="email"
            label={t(TranslateKeys.LABEL.EMAIL)}
            placeholder={t(TranslateKeys.LABEL.EMAIL)}
          />
          <LoginInput
            type="password"
            name="password"
            label={t(TranslateKeys.LABEL.PASSWORD)}
            placeholder={t(TranslateKeys.LABEL.PASSWORD)}
          />
          <Button
            disabled={isLogginIn}
            htmlType="submit"
            type="primary"
            className={`${classes.submitButton} ${classes.rounded}`}
          >
            {t(TranslateKeys.LABEL.LOGIN)}
          </Button>
          <div className={classes.loginError}>{loginFailureComponent}</div>
        </Form>
      </Formik>
      <LangSelector containerStyle={{ marginBottom: 30 }} />
      <Text className={classes.label} style={{ margin: '0 0 10px 0' }}>
        <Link to="/recoverPassword">{t(TranslateKeys.LABEL.RECOVER)}</Link>
      </Text>
    </>
  );
};

Main.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLogginIn: PropTypes.bool.isRequired,
  loginFailure: PropTypes.bool.isRequired,
};

export default Main;
