import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { API } from 'default';
import { getData } from 'util/Api';
import { actions } from './slice';
import {
  paquerypointsItemsSelector,
  paquerypointsLoadedSelector,
} from './selectors';

function* fetchSaga() {
  try {
    const searchParams = new URLSearchParams({
      take: 10000,
      size: 10000,
      search: '',
    });
    const paquerypoints = yield call(
      getData,
      `${API.paqueryPoint.get}?${searchParams.toString()}`,
    );
    yield put({ type: actions.success.type, payload: paquerypoints.content });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* initialSaga() {
  const items = yield select(paquerypointsItemsSelector);
  const loaded = yield select(paquerypointsLoadedSelector);
  if (!items || items.length === 0 || !loaded) {
    yield put({ type: actions.fetch.type });
  }
}

function* refreshPageSaga() {
  yield put({ type: actions.fetch.type });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.initial, initialSaga),
    takeEvery(actions.fetch, fetchSaga),
    takeEvery(actions.refreshPage, refreshPageSaga),
  ]);
}
