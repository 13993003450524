import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { API } from 'default';
import { getData } from 'util/Api';
import { authProfile } from 'redux/auth/selectors';
import { actions } from './slice';
import { actions as globalsActions } from '../globals/slice';
import { zoneItemsSelector } from './selectors';

// Esta operacion es requerida debido a que el backend nos manda la prop
// geoJson como string de JSON, por algun motivo axios no lo parsea correctamente
const mapZonesWithGeojsonParsed = (zone) => {
  try {
    let geoJson;
    if (zone.geoJson) {
      geoJson = JSON.parse(zone.geoJson);
    }
    return {
      ...zone,
      geoJson,
    };
  } catch (error) {
    // En caso de error se ignora zona
  }
  return zone;
};

function* fetchSaga() {
  try {
    const { marketplace } = yield select(authProfile);
    const searchParams = new URLSearchParams({
      logisticOperatorId: marketplace.ownerId,
    });
    const zones = yield call(
      getData,
      `${API.zones.getAll}?${searchParams.toString()}`,
    );
    yield put({
      type: actions.success.type,
      payload: zones.map(mapZonesWithGeojsonParsed),
    });
  } catch (error) {
    yield put({ type: actions.failure.type });
  }
}

function* initialSaga() {
  const items = yield select(zoneItemsSelector);
  if (!items || Object.values(items).length === 0) {
    yield put({ type: actions.fetch.type });
  }
}

function* refreshPageSaga() {
  yield put({ type: actions.fetch.type });
}

export default function* rootSaga() {
  yield all([
    takeEvery([actions.initial, globalsActions.success], initialSaga),
    takeEvery(actions.fetch, fetchSaga),
    takeEvery(actions.refreshPage, refreshPageSaga),
  ]);
}
