import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, Typography } from 'antd';
import * as Yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LOGIN } from '@paquery-team/lib-paquery-language/keys';
import LoginInput from './Input';
import useStyles from '../styles';

const TranslateKeys = LOGIN.CONFIRMRECOVERY;

const { Text } = Typography;

const initialValues = {
  code: '',
  NewPassword: '',
  confirmPassword: '',
};

const ConfirmRecovery = ({ onSubmit, URL }) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t(TranslateKeys.VALIDATION.CODENOTEMPTY)),
    NewPassword: Yup.string().required(
      t(TranslateKeys.VALIDATION.PASSWORDNOTEMPTY),
    ),
    confirmPassword: Yup.string().test(
      'password-match',
      t(TranslateKeys.VALIDATION.PASSWORDNOTMATCH),
      function callback(value) {
        return this.parent.NewPassword === value;
      },
    ),
  });

  const displayError = (message) => {
    setError(<Text className={classes.label}>{message}</Text>);
    setTimeout(() => {
      setError(null);
    }, 1500);
    setLoading(false);
  };
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const { code, NewPassword } = values;
      await axios.post(URL, { code, NewPassword });
      history.push('/login');
    } catch (ex) {
      displayError('El codigo de verificacion no es valido');
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit || handleSubmit}
      >
        <Form>
          <Text className={classes.label}>
            {t(TranslateKeys.MESSAGES.INFORMATION)}
          </Text>
          <LoginInput
            type="text"
            name="code"
            label={t(TranslateKeys.LABEL.INVALIDVERIFICATIONCODE)}
            placeholder={t(TranslateKeys.LABEL.INVALIDVERIFICATIONCODE)}
          />
          <LoginInput
            type="password"
            name="NewPassword"
            label={t(TranslateKeys.LABEL.PASSWORD)}
            placeholder={t(TranslateKeys.LABEL.PASSWORD)}
          />
          <LoginInput
            type="password"
            name="confirmPassword"
            label={t(TranslateKeys.LBAEL.CONFIRMPASSWORD)}
            placeholder={t(TranslateKeys.LABEL.CONFIRMPASSWORD)}
          />
          <div style={{ height: 30, marginTop: 10 }}>{error}</div>
          <Button
            disabled={isLoading}
            htmlType="submit"
            type="primary"
            className={`${classes.submitButton} ${classes.rounded}`}
          >
            {t(TranslateKeys.SUBMIT)}
          </Button>
        </Form>
      </Formik>
    </>
  );
};

ConfirmRecovery.propTypes = {
  onSubmit: PropTypes.func,
  URL: PropTypes.string.isRequired,
};

ConfirmRecovery.defaultProps = {
  onSubmit: null,
};

export default ConfirmRecovery;
