import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useGlobals from 'redux/globals';
import { selectGlobals } from 'redux/globals/selectors';
import { authProfile } from 'redux/auth/selectors';
import { logout } from 'redux/logout';
import { Spin } from 'antd';
import { NavigationLayout } from '@paquery-team/lib-app-layout';
import Logo from 'default/paquery-logo.png';
import useZones from 'redux/zones';
import useMenu from 'components/menu/useMenu';
import { SITELINKS } from 'default';
import PackageHistory from './packageHistory';
import PackageWithPaquers from './packageWithPaquers';
import PackageView from './packageView';
import Dashboard from './dashboard';
import PackageWithPaquersHistorical from './packageWithPaquersHistoric';

const AppRoute = () => {
  const dispatch = useDispatch();
  const menuItems = useMenu();
  useGlobals();
  useZones();
  const profile = useSelector(authProfile);
  const globals = useSelector(selectGlobals);

  if (!globals.loaded) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  const userName = `${profile.name} ${profile.lastName}`;
  return (
    <NavigationLayout
      menuItems={menuItems}
      userName={userName}
      logo={Logo}
      logout={() => dispatch(logout())}
      showLangSelector={false}
    >
      <Switch>
        <Route
          path={SITELINKS.paquers.packagesBy}
          render={(props) => <PackageWithPaquers {...props} />}
        />
        <Route
          path={SITELINKS.packages.history}
          render={(props) => <PackageHistory {...props} />}
        />
        <Route
          path={SITELINKS.packages.view}
          render={(props) => <PackageView {...props} />}
        />
        <Route
          path={SITELINKS.dashboard}
          render={(props) => <Dashboard {...props} />}
        />
        <Route
          path={SITELINKS.paquers.packagesHistorical}
          render={(props) => <PackageWithPaquersHistorical {...props} />}
        />
        <Redirect to={SITELINKS.paquers.packagesBy} />
      </Switch>
    </NavigationLayout>
  );
};
export default AppRoute;
