import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import EsLang from './images/es.png';
import EnLang from './images/en.png';

const LangButton = ({ id, selected, icon }) => {
  const classes = useStyles({ icon });
  const { i18n } = useTranslation();
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <button
      type="button"
      className={`${classes.buttonLang} ${selected && classes.buttonSelected}`}
      onClick={() => i18n.changeLanguage(id)}
    />
  );
};

LangButton.propTypes = {
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};

const LangSelector = ({ containerStyle }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const languages = [
    {
      id: 'es',
      selected: language === 'es',
      icon: EsLang,
    },
    {
      id: 'en',
      selected: language === 'en',
      icon: EnLang,
    },
  ];
  return (
    <div style={containerStyle}>
      {languages.map((lang) => (
        <LangButton
          key={lang.id}
          id={lang.id}
          selected={lang.selected}
          icon={lang.icon}
          alt={lang.alt}
        />
      ))}
    </div>
  );
};

LangSelector.propTypes = {
  containerStyle: PropTypes.shape({}),
};

LangSelector.defaultProps = {
  containerStyle: {},
};

export default LangSelector;
